/* arimo-regular - latin */
@font-face {
	font-family: 'Arimo';
	font-style: normal;
	font-weight: 400;
	src: url('/static/fonts/arimo-v27-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('/static/fonts/arimo-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/static/fonts/arimo-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/static/fonts/arimo-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('/static/fonts/arimo-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/static/fonts/arimo-v27-latin-regular.svg#Arimo') format('svg'); /* Legacy iOS */
  }
  /* arimo-700 - latin */
  @font-face {
	font-family: 'Arimo';
	font-style: normal;
	font-weight: 700;
	src: url('/static/fonts/arimo-v27-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('/static/fonts/arimo-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/static/fonts/arimo-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/static/fonts/arimo-v27-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('/static/fonts/arimo-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/static/fonts/arimo-v27-latin-700.svg#Arimo') format('svg'); /* Legacy iOS */
  }
  
  body, html  {
	height:auto	;
	background: #FEFCFF;
	font-family: "Arimo", sans-serif;
  }

 #wrapper  {
	height:auto	;
	background: #FEFCFF;

	font-family: "Arimo", sans-serif;
  }



