html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.ant-tooltip-inner {
  display: none;
}

.agbtext {
  font-size: 12px;
  font-style: italic;
  padding-top: 15px;
}

.MuiFormControl-fullWidth {
  margin-bottom: 8px !important;
}

.error {
  color: #bf1650 !important;
}

.error-agb {
  color: #bf1650;
}

.duplicate-email {
  margin-bottom: 20px;
  display: block;
  padding: 5px;
}

.react-tel-input .selected-flag .flag {
  margin-top: -12px!important;
}
